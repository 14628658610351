import React, { useEffect, useRef, useState } from 'react'
import { ResizeSensor } from 'css-element-queries'
import PropTypes from 'prop-types'

const propTypes = {
  handleSize: PropTypes.number,
  handle: PropTypes.node,
  hover: PropTypes.bool,
  leftImage: PropTypes.string.isRequired,
  leftImageAlt: PropTypes.string,
  leftImageCss: PropTypes.object, // eslint-disable-line
  leftImageLabel: PropTypes.string,
  onSliderPositionChange: PropTypes.func,
  rightImage: PropTypes.string.isRequired,
  rightImageAlt: PropTypes.string,
  rightImageCss: PropTypes.object, // eslint-disable-line
  rightImageLabel: PropTypes.string,
  skeleton: PropTypes.element,
  sliderLineColor: PropTypes.string,
  sliderLineWidth: PropTypes.number,
  sliderPositionPercentage: PropTypes.number,
}

const defaultProps = {
  handleSize: 46,
  handle: null,
  hover: false,
  leftImageAlt: '',
  leftImageCss: {},
  leftImageLabel: null,
  onSliderPositionChange: () => {},
  rightImageAlt: '',
  rightImageCss: {},
  rightImageLabel: null,
  skeleton: null,
  sliderLineColor: '#ffffff',
  sliderLineWidth: 4,
  sliderPositionPercentage: 0.5,
}

function ReactCompareImage(props) {
  const {
    handleSize,
    handle,
    hover,
    leftImage,
    leftImageAlt,
    leftImageCss,
    leftImageLabel,
    onSliderPositionChange,
    rightImage,
    rightImageAlt,
    rightImageCss,
    rightImageLabel,
    skeleton,
    sliderLineColor,
    sliderLineWidth,
    sliderPositionPercentage,
  } = props

  // 0 to 1
  const [sliderPosition, setSliderPosition] = useState(sliderPositionPercentage)
  const [containerWidth, setContainerWidth] = useState(0)
  const [leftImgLoaded, setLeftImgLoaded] = useState(false)
  const [rightImgLoaded, setRightImgLoaded] = useState(false)
  const [isSliding, setIsSliding] = useState(false)

  const containerRef = useRef()
  const rightImageRef = useRef()
  const leftImageRef = useRef()

  // keep track container's width in local state
  useEffect(() => {
    const updateContainerWidth = () => {
      const currentContainerWidth =
        containerRef.current.getBoundingClientRect().width
      setContainerWidth(currentContainerWidth)
    }

    // initial execution must be done manually
    updateContainerWidth()

    // update local state if container size is changed
    const containerElement = containerRef.current
    const resizeSensor = new ResizeSensor(containerElement, () => {
      updateContainerWidth()
    })

    return () => {
      resizeSensor.detach(containerElement)
    }
  }, [])

  useEffect(() => {
    // consider the case where loading image is completed immediately
    // due to the cache etc.
    const alreadyDone = leftImageRef.current.complete
    alreadyDone && setLeftImgLoaded(true)

    return () => {
      // when the left image source is changed
      setLeftImgLoaded(false)
    }
  }, [leftImage])

  useEffect(() => {
    // consider the case where loading image is completed immediately
    // due to the cache etc.
    const alreadyDone = rightImageRef.current.complete
    alreadyDone && setRightImgLoaded(true)

    return () => {
      // when the right image source is changed
      setRightImgLoaded(false)
    }
  }, [rightImage])

  const allImagesLoaded = rightImgLoaded && leftImgLoaded

  useEffect(() => {
    const handleSliding = (event) => {
      const e = event || window.event

      // console.log('e', e)

      // Calc Cursor Position from the left edge of the viewport
      const cursorXfromViewport = e.touches ? e.touches[0].pageX : e.pageX

      // Calc Cursor Position from the left edge of the window (consider any page scrolling)
      const cursorXfromWindow = cursorXfromViewport - window.pageXOffset

      // Calc Cursor Position from the left edge of the image
      const imagePosition = rightImageRef.current.getBoundingClientRect()
      let pos = cursorXfromWindow - imagePosition.left

      // Set minimum and maximum values to prevent the slider from overflowing
      const minPos = 0 + sliderLineWidth / 2
      const maxPos = containerWidth - sliderLineWidth / 2

      if (pos < minPos) pos = minPos
      if (pos > maxPos) pos = maxPos

      setSliderPosition(pos / containerWidth)

      // If there's a callback function, invoke it everytime the slider changes
      if (onSliderPositionChange) {
        onSliderPositionChange(pos / containerWidth)
      }
    }

    const startSliding = (e) => {
      setIsSliding(true)

      // Prevent default behavior other than mobile scrolling
      if (!('touches' in e)) {
        e.preventDefault()
      }

      // Slide the image even if you just click or tap (not drag)
      handleSliding(e)

      window.addEventListener('mousemove', handleSliding) // 07
      window.addEventListener('touchmove', handleSliding) // 08
    }

    const finishSliding = () => {
      setIsSliding(false)
      window.removeEventListener('mousemove', handleSliding)
      window.removeEventListener('touchmove', handleSliding)
    }

    const containerElement = containerRef.current

    if (allImagesLoaded) {
      // it's necessary to reset event handlers each time the canvasWidth changes

      // for mobile
      containerElement.addEventListener('touchstart', startSliding) // 01
      window.addEventListener('touchend', finishSliding) // 02

      // for desktop
      if (hover) {
        containerElement.addEventListener('mousemove', handleSliding) // 03
        containerElement.addEventListener('mouseleave', finishSliding) // 04
      } else {
        containerElement.addEventListener('mousedown', startSliding) // 05
        window.addEventListener('mouseup', finishSliding) // 06
      }
    }

    return () => {
      // cleanup all event resteners
      containerElement.removeEventListener('touchstart', startSliding) // 01
      window.removeEventListener('touchend', finishSliding) // 02
      containerElement.removeEventListener('mousemove', handleSliding) // 03
      containerElement.removeEventListener('mouseleave', finishSliding) // 04
      containerElement.removeEventListener('mousedown', startSliding) // 05
      window.removeEventListener('mouseup', finishSliding) // 06
      window.removeEventListener('mousemove', handleSliding) // 07
      window.removeEventListener('touchmove', handleSliding) // 08
    }
  }, [allImagesLoaded, containerWidth, hover, sliderLineWidth]) // eslint-disable-line

  // Image size set as follows.
  //
  // 1. right(under) image:
  //     width  = 100% of container width
  //     height = auto
  //
  // 2. left(over) imaze:
  //     width  = 100% of container width
  //     height = right image's height
  //              (protrudes is hidden by css 'object-fit: hidden')
  const styles = {
    handleCustom: {
      alignItems: 'center',
      boxSizing: 'border-box',
      display: 'flex',
      flex: '1 0 auto',
      height: 'auto',
      justifyContent: 'center',
      width: 'auto',
    },
    leftArrow: {
      border: `inset ${handleSize * 0.15}px rgba(0,0,0,0)`,
      borderRight: `${handleSize * 0.15}px solid ${sliderLineColor}`,
      height: '0px',
      marginLeft: `-${handleSize * 0.25}px`, // for IE11
      marginRight: `${handleSize * 0.25}px`,
      width: '0px',
    },
    rightArrow: {
      border: `inset ${handleSize * 0.15}px rgba(0,0,0,0)`,
      borderLeft: `${handleSize * 0.15}px solid ${sliderLineColor}`,
      height: '0px',
      marginRight: `-${handleSize * 0.25}px`, // for IE11
      width: '0px',
    },
  }

  return skeleton && !allImagesLoaded ? (
    <div className="before-after-container">{skeleton}</div>
  ) : (
    <div
      ref={containerRef}
      data-testid="container"
      className="before-after-container beforeafterslider shadow-lg border-radius overflow-hidden"
    >
      <img
        className="ba-image right"
        onLoad={() => setRightImgLoaded(true)}
        loading="lazy"
        alt={rightImageAlt}
        data-testid="right-image"
        ref={rightImageRef}
        src={rightImage}
      />
      <img
        className="ba-image left"
        style={{
          clip: `rect(auto, ${containerWidth * sliderPosition}px, auto, auto )`,
        }}
        onLoad={() => setLeftImgLoaded(true)}
        loading="lazy"
        alt={leftImageAlt}
        data-testid="left-image"
        ref={leftImageRef}
        src={leftImage}
      />
      <div className="beforeafterslideroverlay">
        {/* labels */}
        {leftImageLabel && (
          <div
            className="label left labelsliderhover"
            style={{ opacity: isSliding ? 0 : 0 }}
          >
            {leftImageLabel}
          </div>
        )}
        {rightImageLabel && (
          <div
            className="label right labelsliderhover"
            style={{ opacity: isSliding ? 0 : 0 }}
          >
            {rightImageLabel}
          </div>
        )}
      </div>

      <div
        className="slider-ba"
        style={{
          cursor: !hover && 'ew-resize',
          left: `${containerWidth * sliderPosition - handleSize / 2}px`,
          width: `${handleSize}px`,
        }}
      >
        <div
          className="ba-seperator"
          style={{ background: sliderLineColor, width: sliderLineWidth + 'px' }}
        />

        {handle ? (
          <div style={styles.handleCustom}>{handle}</div>
        ) : (
          <div
            className="ba-default-drag-handle"
            style={{
              border: sliderLineWidth + 'px solid ' + sliderLineColor,
              height: handleSize + 'px',
              width: handleSize + 'px',
            }}
          >
            <div style={styles.leftArrow} />
            <div style={styles.rightArrow} />
          </div>
        )}
        <div
          className="ba-seperator"
          style={{ background: sliderLineColor, width: sliderLineWidth + 'px' }}
        />
      </div>
    </div>
  )
}
ReactCompareImage.propTypes = propTypes
ReactCompareImage.defaultProps = defaultProps
export default ReactCompareImage
